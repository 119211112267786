.home {
  background-color: #22a5e5;
  color: white;
  text-align: center;
  display: inline-block;
  min-height: 100vh;
  min-width: 100%;
  font-family: 'Poppins', sans-serif;
}

.home img {
  width: 35vw;
  padding: 20px;
}

.home h2{
  font-size: 2rem;
}

.home-selection-container{

}

.home-selection-container button{
  width: 20vw;
  font-size: 2rem;
  margin: 20px;
  font-weight: 800;
  color: white;
  background-color: #ba0b0b;
  border: none;
}

@media (max-width: 1000px) {
  .home-selection-container button{
    width: 40vw;
    margin: 10px;
    font-size: 4vw;
  }
}